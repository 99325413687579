/* eslint-disable no-unused-vars */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */

import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Divider,
    Form,
    List,
    message,
    Modal,
    Radio,
    Space,
    Switch,
    Tag,
    InputNumber,
    Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdDescriptionColumn from '~/components/AdDescriptionColumn/AdDescriptionColumn';
import AdModalHeader from '~/components/AdModalHeader/AdModalHeader';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import Table from '~/components/Table';
import { wrapDialog } from '~/helpers/dialog-helper';
import { wrapForm } from '~/helpers/form-helper';
import { maskMoney } from '~/helpers/mask-helper';
import { parseDecimal } from '~/helpers/parser-helper';
import useAxios from '~/hooks/use-axios';
import useDidMount from '~/hooks/use-did-mount';
import { PRICING_RULE_STATUS } from '~/values/enums';

import styles from './PricingDialog.module.scss';

const MODAL_STYLE = { top: 60 };
const { Column } = Table;

const STRATEGIES = [
    'buybox_to_win',
    'buybox_to_win_exceptions',
    'competitor_ranking_to_win',
    'competitor_ranking_to_win_execptions',
];

const BASE_DTO = {
    accountIntegrationId: Number(),
    companyId: Number(),
    considerShippingPrice: false,
    countryCode: String(),
    createdAt: String(),
    currencyCode: String(),
    id: String(),
    listingId: String(),
    managerId: Number(),
    marketplaceId: Number(),
    maxPrice: Number(),
    minPrice: Number(),
    overrideAuthority: true,
    requiresConfirmation: false,
    ruleStatus: String(),
    ruleStrategy: 'buybox_to_win',
    ruleType: String(),
    updatedAt: String(),
};

const PricingDialog = ({
    data, onCancel, onCancelDialog,
    ...others
}) => {
    const { t } = useTranslation('PRICING_DIALOG');
    const axios = useAxios({ baseURL: process.env.REACT_APP_URL_API_GO });
    const [form] = Form.useForm();
    const [initialData, setInitialData] = useState(BASE_DTO);
    const [updatedData, setUpdatedData] = useState(BASE_DTO);
    const [isLoading, setIsLoading] = useState(true);
    const [hasFormError, setHasFormError] = useState(data.map(ad => {
        return {
            listingId: ad.id,
            min: true,
            max: true,
        };
    }));
    const priceValidations = useRef(data.map(ad => {
        return {
            listingId: ad.id,
            sku: ad.sku,
            minPrice: 0,
            maxPrice: 0,
        };
    }));

    const RULE_LIST = useMemo(() => {
        return {
            ruleStrategy: {
                title: t('OBJECTIVE_TITLE'),
                items: [
                    {
                        title: t('WIN_BUYBOX_OBJECTIVE_TITLE'),
                        description: t('WIN_BUYBOX_OBJECTIVE_DESCRIPTION'),
                        disabled: false,
                    },
                    {
                        title: t('WIN_BUYBOX_EXCEPTION_OBJECTIVE_TITLE'),
                        description: t('WIN_BUYBOX_EXCEPTION_OBJECTIVE_DESCRIPTION'),
                        disabled: true,
                    },
                    {
                        title: t('WIN_NON_BUYBOX_OBJECTIVE_TITLE'),
                        description: t('WIN_NON_BUYBOX_OBJECTIVE_DESCRIPTION'),
                        disabled: false,
                    },
                ],
            },
            overrideAuthority: {
                title: t('OVERRIDE_TITLE'),
                subtitle: t('OVERRIDE_SUBTITLE'),
                items: [
                    {
                        title: t('REGAIN_OVERRIDE_TITLE'),
                        description: t('REGAIN_OVERRIDE_DESCRIPTION'),
                        disabled: false,
                    },
                    {
                        title: t('LOSE_OVERRIDE_TITLE'),
                        description: t('LOSE_OVERRIDE_DESCRIPTION'),
                        disabled: false,
                    },
                ],
            },
            requiresConfirmation: {
                title: t('PERMISSION_TITLE'),
                items: [
                    {
                        title: t('ASK_PERMISSION_TITLE'),
                        description: t('ASK_PERMISSION_DESCRIPTION'),
                        disabled: false,
                    },
                    {
                        title: t('DONT_ASK_PERMISSION_TITLE'),
                        description: t('DONT_ASK_PERMISSION_DESCRIPTION'),
                        disabled: false,
                    },
                ],
            },
        };
    }, [t]);

    const requestDataSource = useCallback(async () => {
        try {
            const response = await axios.get(`v1/rules/${data[0].listingId || data[0].id}`);
            setInitialData(response.data);
            setUpdatedData(response.data);
            form.setFieldsValue({
                [`minPrice:${data[0].id}`]: response.data.minPrice,
                [`maxPrice:${data[0].id}`]: response.data.maxPrice,
            });

            setIsLoading(false);
        } catch (e) {
            message.error('Connection Error: ', e);
        }
    }, [axios, form, data]);

    useDidMount(() => {
        if (data[0].ruleStatus !== PRICING_RULE_STATUS.NO_RULE) {
            requestDataSource();
            setHasFormError(data.map(ad => {
                return {
                    listingId: ad.id,
                    min: false,
                    max: false,
                };
            }));
        } else {
            setIsLoading(false);
        }
    }, [data, form, initialData]);

    const onSwitchChange = useCallback(value => {
        const newValues = { ...updatedData };
        newValues.ruleStatus = value ? PRICING_RULE_STATUS.ENABLED : PRICING_RULE_STATUS.DISABLED;
        setUpdatedData(newValues);
    }, [updatedData]);

    const renderStatus = useMemo(() => {
        return (
            <div className={styles.ruleStatusContainer}>
                <Switch
                    checkedChildren={t('STATUS_ENABLED')}
                    unCheckedChildren={
                        data[0].ruleStatus === PRICING_RULE_STATUS.PAUSED
                            ? t('STATUS_PAUSED') : t('STATUS_DISABLED')
                    }
                    onChange={value => onSwitchChange(value)}
                    size="default"
                    defaultChecked={data[0].ruleStatus === PRICING_RULE_STATUS.ENABLED}
                />
            </div>
        );
    }, [t, data, onSwitchChange]);

    const renderHeader = useMemo(() => {
        if (data.length > 1) return null;

        return (
            <div className={styles.pricingHeaderContainer}>
                <AdModalHeader listingId={data[0].listingId || data[0].id} modal="pricing" />
                {data[0].ruleStatus === PRICING_RULE_STATUS.NO_RULE ? null : (
                    <div className={styles.switchContainer}>
                        <div className={styles.infoLineTitle}>{t('RULE_STATUS')}</div>
                        {renderStatus}
                    </div>
                )}
            </div>
        );
    }, [t, renderStatus, data]);

    const onRadioChange = useCallback((e, radioGroup) => {
        const newValues = { ...updatedData };

        switch (radioGroup) {
            case 0:
                newValues.ruleStrategy = STRATEGIES[e.target.value];
                setUpdatedData(newValues);
                break;
            case 1:
                newValues.overrideAuthority = !e.target.value;
                setUpdatedData(newValues);
                break;
            case 2:
                newValues.requiresConfirmation = !e.target.value;
                setUpdatedData(newValues);
                break;
            default:
                break;
        }
    }, [updatedData]);

    const renderExtraInfo = useCallback(item => {
        if (!item.disabled) {
            return (
                <Tooltip
                    title={item.description}
                    overlayClassName={styles.tooltipInformationCard}
                >
                    <InfoCircleOutlined />
                </Tooltip>
            );
        }

        return (
            <Tag className={classNames([styles.tag, styles.comingSoon])}>
                {t('COMING_SOON')}
            </Tag>
        );
    }, [t]);

    const onShippingSwitch = useCallback(value => {
        const newValue = updatedData;
        newValue.considerShippingPrice = value;
        setUpdatedData(newValue);
    }, [updatedData]);

    const renderShippingSwitch = useCallback(() => {
        return (
            <div className={styles.shippingSwitch}>
                <Switch
                    onChange={value => onShippingSwitch(value)}
                    size="default"
                    defaultChecked={updatedData.considerShippingPrice}
                />
                &nbsp;&nbsp;
                {t('SHIPPING_SWITCH_TEXT')}
                &nbsp;&nbsp;
                <div className={styles.shippingTooltip}>
                    <Tooltip
                        title={t('CONSIDER_SHIPPING_TOOLTIP')}
                        overlayClassName={styles.tooltipInformationCard}

                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            </div>
        );
    }, [t, onShippingSwitch, updatedData]);

    const renderRuleGroup = useCallback(ruleType => {
        const itemsList = RULE_LIST[ruleType].items;
        let radioGroup;
        let radioValue;
        let shippingSwitch = false;

        switch (ruleType) {
            case 'ruleStrategy':

                // Base value - no rule
                radioValue = 0;
                // Data loaded - already has rule
                if (initialData !== BASE_DTO) {
                    radioValue = STRATEGIES.indexOf(initialData.ruleStrategy);
                }
                // New selected value
                if (updatedData.ruleStrategy !== initialData.ruleStrategy) {
                    radioValue = STRATEGIES.indexOf(updatedData.ruleStrategy);
                }

                radioGroup = 0;
                shippingSwitch = radioValue >= 2;
                break;
            case 'overrideAuthority':

                // Base value - no rule
                radioValue = 0;
                // Data loaded - already has rule
                if (initialData !== BASE_DTO) {
                    radioValue = initialData.overrideAuthority ? 0 : 1;
                }
                // New selected value
                if (updatedData.overrideAuthority !== initialData.overrideAuthority) {
                    radioValue = updatedData.overrideAuthority ? 0 : 1;
                }

                radioGroup = 1;
                break;
            case 'requiresConfirmation':

                // Base value - no rule
                radioValue = 1;
                // Data loaded - already has rule
                if (initialData !== BASE_DTO) {
                    radioValue = initialData.requiresConfirmation ? 0 : 1;
                }
                // New selected value
                if (updatedData.requiresConfirmation !== initialData.requiresConfirmation) {
                    radioValue = updatedData.requiresConfirmation ? 0 : 1;
                }

                radioGroup = 2;
                break;
            default:
                return null;
        }

        return (
            <Radio.Group
                className={styles.group}
                onChange={e => onRadioChange(e, radioGroup)}
                value={radioValue}
            >
                {itemsList.map((item, index) => {
                    const titleStyle = item.disabled ? styles.comingSoonTitle : styles.optionTitle;

                    return (
                        <List.Item key={item.title}>
                            <List.Item.Meta
                                avatar={(
                                    <Radio value={index} disabled={item.disabled} />
                                )}
                                title={(
                                    <div className={titleStyle}>
                                        <div>
                                            {item.title}
                                            &nbsp;
                                            {renderExtraInfo(item)}
                                        </div>
                                        {shippingSwitch && index >= 2 ? (
                                            <div>
                                                {renderShippingSwitch()}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            />
                        </List.Item>
                    );
                })}
            </Radio.Group>
        );
    }, [updatedData, initialData, RULE_LIST, onRadioChange, renderExtraInfo, renderShippingSwitch]);

    const renderRuleTitle = useCallback(rule => {
        return (
            <div className={styles.listTitleContainer}>
                <Title level={4} type="secondary" className={styles.listTitle}>
                    {rule.title}
                </Title>
            </div>
        );
    }, []);

    const renderRules = useMemo(() => {
        return Object.keys(RULE_LIST).map(ruleType => {
            return (
                <div className={styles.title} key={ruleType}>
                    {renderRuleTitle(RULE_LIST[ruleType])}
                    {renderRuleGroup(ruleType)}
                </div>
            );
        });
    }, [RULE_LIST, renderRuleGroup, renderRuleTitle]);

    const validateAndUpdateForm = useCallback(async adData => {
        const newValues = [...priceValidations.current];
        const error = [...hasFormError];

        try {
            const result = await form.validateFields();

            // Get field values
            Object.entries(result).forEach(field => {
                const [minMax, id] = field[0].split('Price:');
                const newAd = newValues.find(ad => ad.listingId === id);
                const newError = error.find(ad => ad.listingId === id);
                const isMin = minMax === 'min';

                // Define messages
                if (isMin) { [, newAd.minPrice] = field; newError.min = false; }
                if (!isMin) { [, newAd.maxPrice] = field; newError.max = false; }
            });
        } catch (err) {

            // Get field values
            Object.entries(err.values).forEach(field => {
                const [minMax, id] = field[0].split('Price:');
                const newError = error.find(ad => ad.listingId === id);
                const newAd = newValues.find(ad => ad.listingId === id);
                const isMin = minMax === 'min';

                if (isMin) { [, newAd.minPrice] = field; newError.min = false; }
                if (!isMin) { [, newAd.maxPrice] = field; newError.max = false; }
            });

            // Validate fields
            err.errorFields.forEach(input => {
                const [minMax, id] = input.name[0].split('Price:');
                const newError = error.find(ad => ad.listingId === id);
                const isMin = minMax === 'min';

                if (isMin) newError.min = true;
                if (!isMin) newError.max = true;
            });
        }

        priceValidations.current = newValues;
        setHasFormError(error);
    }, [form, priceValidations, hasFormError]);

    const onFormChange = useCallback(adData => {
        if (data.length === 1) {
            const newValues = { ...updatedData };
            newValues.minPrice = form.getFieldValue(`minPrice:${adData.id}`);
            newValues.maxPrice = form.getFieldValue(`maxPrice:${adData.id}`);
            setUpdatedData(newValues);
        }

        validateAndUpdateForm(adData);
    }, [form, data, updatedData, validateAndUpdateForm]);

    const getInputMessage = useCallback(adData => {
        const minPrice = form.getFieldValue(`minPrice:${adData.id}`) || 0;
        const maxPrice = form.getFieldValue(`maxPrice:${adData.id}`) || 0;

        return {
            minMessage: t('VALIDATIONS:VALIDATE_MIN_MAX_%', {
                value: `${Math.round((minPrice / adData.price) * 100)} %`,
            }),
            maxMessage: t('VALIDATIONS:VALIDATE_MIN_MAX_%', {
                value: `${Math.round((maxPrice / adData.price) * 100)} %`,
            }),
        };
    }, [t, form]);

    const renderPriceRangeInputFields = useCallback(adData => {
        const initialMinPrice = initialData !== BASE_DTO ? initialData.minPrice : 0;
        const initialMaxPrice = initialData !== BASE_DTO ? initialData.maxPrice : 0;
        const formStyle = data.length === 1 ? styles.inputFieldsSingle : styles.inputFieldsMass;
        const { minMessage, maxMessage } = getInputMessage(adData);
        const minRules = [{
            required: true, type: 'number', min: 0.01, message: t('VALIDATIONS:VALIDATE_REQUIRED'),
        }];
        const maxRules = [
            { required: true, message: t('VALIDATIONS:VALIDATE_REQUIRED') },
            forms => ({
                type: 'number',
                min: forms.getFieldValue(`minPrice:${adData.id}`) + 0.001,
                message: t('VALIDATIONS:VALIDATE_LESSER_THAN'),
            }),
        ];

        return (
            <Form
                form={form}
                name={adData.id}
                preserve={false}
                validateTrigger="onBlur"
                className={classNames([styles.inputFieldsCenter, formStyle])}
                initialValues={{
                    [`minPrice:${adData.id}`]: initialMinPrice,
                    [`maxPrice:${adData.id}`]: initialMaxPrice,
                }}
                onValuesChange={() => onFormChange(adData)}
            >
                <Form.Item
                    name={`minPrice:${adData.id}`}
                    label={t('MINIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    rules={minRules}
                    shouldUpdate
                    extra={hasFormError.find(ad => ad.listingId === adData.id).min ? null : minMessage}
                >
                    <InputNumber
                        className={styles.inputField}
                        size={data.length > 1 ? 'middle' : 'large'}
                        type="text"
                        parser={parseDecimal}
                        formatter={value => maskMoney(parseFloat(value || 0), adData)}
                    />
                </Form.Item>
                <Form.Item
                    name={`maxPrice:${adData.id}`}
                    label={t('MAXIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    rules={maxRules}
                    shouldUpdate
                    extra={hasFormError.find(ad => ad.listingId === adData.id).max ? null : maxMessage}
                >
                    <InputNumber
                        className={styles.inputField}
                        size={data.length > 1 ? 'middle' : 'large'}
                        type="text"
                        parser={parseDecimal}
                        formatter={value => maskMoney(parseFloat(value || 0), adData)}
                    />
                </Form.Item>
            </Form>
        );
    }, [t, data, form, initialData, onFormChange, getInputMessage, hasFormError]);

    const renderListing = useMemo(() => {
        return (
            <Table
                {...others}
                dataSource={data}
                scroll={{ y: 450 }}
                pagination={false}
            >
                <Column
                    title={t('AD_COLUMN')}
                    key="title"
                    dataIndex="title"
                    render={(_, adData) => <AdDescriptionColumn adData={adData} />}
                    align="center"
                    width={400}
                />
                <Column
                    title={t('PRICE_COLUMN')}
                    key="price"
                    dataIndex="price"
                    dataSorter="price"
                    render={maskMoney}
                    align="center"
                    width={90}
                />
                <Column
                    title={t('SHIPPING_COLUMN')}
                    key="shippingPrice"
                    dataIndex="shippingPrice"
                    dataSorter="shippingPrice"
                    render={maskMoney}
                    align="center"
                    width={90}
                />
                <Column
                    title={t('PRICE_RANGE_COLUMN')}
                    render={(_, adData) => renderPriceRangeInputFields(adData)}
                    align="center"
                    width={260}
                />
            </Table>
        );
    }, [t, data, others, renderPriceRangeInputFields]);

    const renderPricingSection = useMemo(() => {
        return (
            <div>
                <div className={classNames([styles.listTitleContainer, styles.pricingLimits])}>
                    <Title level={4} type="secondary" className={styles.listTitle}>
                        {t('PRICING_SECTION_TITLE')}
                    </Title>
                    <Tooltip
                        title={t('PRICING_SECTION_SUBTITLE')}
                        overlayClassName={styles.tooltipInformationCard}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                {renderPriceRangeInputFields(data[0])}
            </div>
        );
    }, [data, t, renderPriceRangeInputFields]);

    const renderMassPricingSection = useMemo(() => {
        return (
            <div>
                <div className={styles.center}>
                    <Divider>
                        <div className={styles.listTitleContainer}>
                            <Title level={3} type="secondary" className={styles.listTitle}>
                                {t('PRICING_SECTION_TITLE')}
                            </Title>
                            <Tooltip
                                title={t('PRICING_SECTION_SUBTITLE')}
                                overlayClassName={styles.tooltipInformationCard}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    </Divider>
                </div>
                {renderListing}
            </div>
        );
    }, [renderListing, t]);

    const renderRulesSection = useMemo(() => {
        return (
            <div>
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={10}
                >
                    <Divider>
                        <Title level={3} type="secondary" className={styles.listTitle}>
                            {t('RULES_SECTION_TITLE')}
                        </Title>
                    </Divider>

                    <div className={styles.configurationsContainer}>
                        {renderRules}
                        {data.length > 1 ? null : renderPricingSection}
                    </div>
                </Space>
            </div>
        );
    }, [t, data, renderPricingSection, renderRules]);

    const cancel = useCallback(() => {
        onCancelDialog();
        onCancel();
    }, [onCancel, onCancelDialog]);

    const onSubmit = useCallback(async () => {
        try {
            if (data[0].ruleStatus !== PRICING_RULE_STATUS.NO_RULE) {
                await axios.put(`v1/rules/${updatedData.id}`, {
                    ...updatedData,
                    listingId: data[0].id,
                    sku: data[0].sku,
                });
            } else {
                await axios.post('v1/rules', {
                    data: priceValidations.current.map(obj => {
                        obj.ruleStrategy = updatedData.ruleStrategy;
                        obj.considerShippingPrice = updatedData.considerShippingPrice;
                        obj.overrideAuthority = updatedData.overrideAuthority;
                        obj.requiresConfirmation = updatedData.requiresConfirmation;
                        return obj;
                    }),
                });
            }

            message.success(t('SUCCESS'));
        } catch (err) {
            message.error(t('ERROR_ON_CREATE_RULE'));
        }

        cancel();
    }, [t, axios, cancel, data, updatedData, priceValidations]);

    const renderSubmitButtons = useMemo(() => {
        return (
            <div className={styles.buttonContainer}>
                {/* add tooltip for pendencies checkbox */}
                {/* add logic for pendencies checkbox */}
                {hasFormError.some(ad => ad.min || ad.max) ? (
                    <Tooltip title={t('CORRECT_INVALID_ADS')}>
                        <Button
                            className={styles.submitButtons}
                            onClick={onSubmit}
                            disabled
                        >
                            {t('CONFIRM')}
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        className={styles.submitButtons}
                        onClick={onSubmit}
                    >
                        {t('CONFIRM')}
                    </Button>
                )}
                <Button type="default" className={[styles.submitButtons, styles.cancelButton]} onClick={cancel}>
                    {t('CANCEL')}
                </Button>
            </div>
        );
    }, [t, hasFormError, cancel, onSubmit]);

    return (
        <Modal
            {...others}
            title={data.length > 1 ? null : t('PRICING_CONFIGURATION_TITLE')}
            closable
            destroyOnClose
            width={940}
            style={MODAL_STYLE}
            onCancel={cancel}
            footer={renderSubmitButtons}
        >
            <Spinner
                spinning={isLoading}
            >
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={25}
                >
                    {renderHeader}
                    {renderRulesSection}
                    {data.length > 1 ? renderMassPricingSection : null}
                </Space>
            </Spinner>
        </Modal>
    );
};

export default wrapDialog(wrapForm(PricingDialog));
