import React, { useCallback, useMemo } from 'react';

import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { OUTSIDE_BUYBOX_STATUS } from '~/values/enums';

import styles from './OutsideBuyboxTagDescription.module.scss';

const OutsideBuyboxTagDescription = ({ data, openListingBuyboxDialog }) => {
    const { t } = useTranslation('BUYBOX_TAG_DESCRIPTION');

    const { rankingStatus, updatedRankingAt } = data;

    const getIconColor = () => {
        switch (rankingStatus) {
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_WINNING:
                return styles.winner;
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_LOSING:
                return styles.loser;
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING:
                return styles.outsideRanking;
            default:
                return styles.outsideRanking;
        }
    };

    const getTagClasses = () => {
        const classes = [styles.tag, getIconColor()];

        if (openListingBuyboxDialog) {
            classes.push(styles.click);
        }

        return classNames(classes);
    };

    const getTagText = () => {
        switch (rankingStatus) {
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_WINNING:
                return t('WINNING');
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_LOSING:
                return t('LOSING');
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING:
                return t('NOT_COMPETING');
            default: return t('NOT_COMPETING');
        }
    };

    const renderTooltip = () => {
        if (rankingStatus === OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING) {
            return t('NOT_MONITORING');
        }

        return null;
    };

    const handleClick = useCallback(() => {
        openListingBuyboxDialog(data);
    }, [data, openListingBuyboxDialog]);

    const renderLastUpdateTime = useMemo(() => {
        if (!updatedRankingAt) {
            return null;
        }

        const now = new Date();
        const lastUpdateTime = new Date(updatedRankingAt);
        const differenceMillis = now - lastUpdateTime;

        const hours = Math.floor(differenceMillis / (1000 * 60 * 60));
        const days = Math.floor(hours / 24);
        const minutes = Math.floor((differenceMillis % (1000 * 60 * 60)) / (1000 * 60));

        if (days > 0) return t('DAYS', { days });
        return hours === 0 ? t('MINUTES', { minutes }) : t('HOURS', { hours: hours + 1 });
    }, [t, updatedRankingAt]);

    const renderLastUpdate = useMemo(() => {
        if (!renderLastUpdateTime) {
            return null;
        }

        return (
            <div className={styles.lastUpdateContainer}>
                {renderLastUpdateTime}
            </div>
        );
    }, [renderLastUpdateTime]);

    return (
        <div className={styles.mainContainer}>
            <Tooltip title={renderTooltip()}>
                <Tag
                    onClick={openListingBuyboxDialog ? handleClick : null}
                    className={getTagClasses()}
                >
                    {getTagText()}
                </Tag>
            </Tooltip>
            {renderLastUpdate}
        </div>
    );
};

export default OutsideBuyboxTagDescription;
